.profile-dropdown {
    position: absolute;
    top: 3.6rem;
    right: 1rem;
    background: linear-gradient(343deg, #534d9d, #6153b1);
    padding: 0.4rem;
    box-shadow: 0px 0px 3px 0px;
}

.profile-dropdown p {
    margin: 1px;
    cursor: pointer;
}

.MuiBox-root.css-1j1s727 {
    position: fixed;
    height: 100vh;
    z-index: 1;

}

.content {
    margin-left: 270px;
}

.content.content-collpse {
    margin-left: 80px;
}